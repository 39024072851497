import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Collapse,
  CustomInput,
  FormGroup,
  FormText,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from "availity-reactstrap-validation";
import {
  clone,
  compact,
  filter,
  find,
  flatMap,
  get,
  head,
  includes,
  isEmpty,
  isEqual,
  isNil,
  map,
  pullAt,
  set,
  size,
  toLower,
  union,
  without,
} from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsAlt,
  faMinus,
  faPlus,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { saveCampaignClinicalMomentsCMECase } from "../../../actions/campaigns.actions";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  CLINICAL_MOMENTS_CME_QUESTION_SLIDE_TYPES,
  CLINICAL_MOMENTS_CME_SLIDE_TYPES,
  CLINICAL_MOMENTS_SLIDE_TYPES,
  DEFAULT_QUIZ_ANSWER_SHAPE,
  DEFAULT_QUIZ_CME_ANSWER_SHAPE,
  getClinicalMomentContentType,
  getTacticClinicalMomentCMEFormData,
  STATIC_MEDIA_TYPE,
} from "../../../utils/campaign-utils";
import {
  getFieldName,
  getFieldValue,
  getFieldValueCharacters,
  onInvalidTacticSubmit,
} from "../../../utils/form-utils";
import "easymde/dist/easymde.min.css";
import { CampaignTacticSettings } from "./CampaignTacticSettings";
import { useHistory } from "react-router-dom";
import RowToggler from "../../../components/common/RowToggler";
import SimpleMDEEditor from "react-simplemde-editor";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import {
  CampaignFormCommonElements,
  CampaignQuizOrPollInputs,
} from "./CampaignForms";
import { arrayMove } from "../../../utils/general-utils";
import { CampaignCaseSingleImageSection } from "./CampaignCaseSingleImageSection";
import FEED_CARD_TYPE from "../../../constants/feed-card-type-constants";
import {
  CampaignFormButtons,
  SlideBasic,
  SlideConclusion,
} from "./CampaignClinicalMomentForm";
import { getAllProfessionAndSpecialties } from "../../../actions/reference.actions";
import { campaignCertificateUploadCall } from "../../../api/cloud-functions";
import { useDropzone } from "react-dropzone";
import ConfirmModal from "../../../components/modals/ConfirmModal";
import { TacticExpandCollapseAll } from "./CampaignSections";

const EMPTY_SLIDE = {
  section: "",
  content_type: "",
};

const ACTIVITY_SLIDE_TYPES = {
  BASIC: CLINICAL_MOMENTS_SLIDE_TYPES.BASIC,
  QUIZ: CLINICAL_MOMENTS_SLIDE_TYPES.QUIZ,
  POLL: CLINICAL_MOMENTS_SLIDE_TYPES.POLL,
  CONCLUSION: CLINICAL_MOMENTS_SLIDE_TYPES.CONCLUSION,
};

export const CampaignClinicalMomentCMEForm = (props) => {
  const { caseData, activeSection } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const saving = useSelector((state) => state.campaigns.saving);
  const activeCampaign = useSelector((state) => state.campaigns.activeCampaign);
  const [formData, setFormData] = useState({});

  const campaignUuid = get(activeCampaign, "campaignUuid");
  const slides = get(formData, "slides", []);
  const slidesBottom = useRef(null);
  const slideTypes = get(activeSection, "slideTypes");

  const [reorderActive, setReorderActive] = useState(false);
  const toggleReorder = () => setReorderActive(!reorderActive);

  useEffect(() => {
    setFormData(
      getTacticClinicalMomentCMEFormData(
        campaignUuid,
        caseData,
        activeSection,
        user
      )
    );
  }, [campaignUuid, caseData, activeSection, user]);

  useEffect(() => {
    dispatch(getAllProfessionAndSpecialties());
  }, [dispatch]);

  const transformQuestionSections = (slide) => {
    const type = slide.section;
    const questions = get(slide, "questions", []);
    return compact(
      map(questions, (q) => {
        return {
          caption: get(q, "caption"),
          question_options: get(q, "question_options", []),
          section: type,
          content_type: getClinicalMomentContentType(type),
        };
      })
    );
  };

  const transformActivitySections = (slide) => {
    const titleSlide = get(slide, "titleSlide");
    const activitySlides = clone(get(slide, "slides", []));
    if (!titleSlide) {
      return [];
    }

    titleSlide.slideType = CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE;
    activitySlides.unshift(titleSlide);

    return compact(
      map(activitySlides, (q) => {
        return {
          ...q,
          section: "ACTIVITY",
          content_type: getClinicalMomentContentType(q.slideType),
        };
      })
    );
  };

  const onSubmit = async () => {
    const cmeCenterCard = {
      content_type: "CME_HUB_CARD",
      title: get(formData, "cme_center_card.title"),
      heading: get(formData, "cme_center_card.heading"),
      media: [get(formData, "cme_center_card.media")],
    };
    const feedcardActivityType = get(
      formData,
      "cme_feed_card.feed_activity_type"
    );
    const cmeFeedCard = {
      content_type: "FEED_CARD",
      feed_card_type: FEED_CARD_TYPE.HIGHLIGHT,
      feed_card_title: get(formData, "cme_feed_card.feed_card_title"),
      heading: get(formData, "cme_feed_card.heading"),
      feed_card_media: get(formData, "cme_feed_card.feed_card_media"),
      feed_card_label: get(formData, "cme_feed_card.feed_card_label"),
      colour: get(formData, "cme_feed_card.colour"),
    };
    const slides = filter(
      flatMap(formData.slides, (slide) => {
        if (
          includes(CLINICAL_MOMENTS_CME_QUESTION_SLIDE_TYPES, slide.section)
        ) {
          return transformQuestionSections(slide);
        }

        if (isEqual("ACTIVITY", slide.section)) {
          return transformActivitySections(slide);
        }

        return {
          ...slide,
          content_type: getClinicalMomentContentType(slide.section),
        };
      }),
      (s) => !isNil(s.content_type)
    );

    if (isEqual(feedcardActivityType, "show")) {
      slides.unshift(cmeFeedCard);
    }

    slides.unshift(cmeCenterCard);

    const submitData = {
      ...formData,
      slides: slides,
    };

    await dispatch(saveCampaignClinicalMomentsCMECase(submitData, history));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const addSlide = () => {
    slides.push(clone(EMPTY_SLIDE));

    setFormData(clone(formData));

    slidesBottom.current.scrollIntoView({ behavior: "smooth" });
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }

    setFormData(
      clone(set(formData, "slides", arrayMove(slides, oldIndex, newIndex)))
    );
  };

  const renderSlide = (slide, index) => {
    return (
      <CampaignSlideContainer
        key={index}
        slideIndex={index}
        slideTypes={slideTypes}
        formData={formData}
        setFormData={setFormData}
        reorderActive={reorderActive}
      />
    );
  };

  const SortableSlide = SortableElement(({ position, slide }) => {
    return (
      <CampaignSlideContainer
        key={position}
        slideIndex={position}
        slideTypes={slideTypes}
        formData={formData}
        setFormData={setFormData}
        reorderActive={reorderActive}
      />
    );
  });

  const SortableSlidesContainer = SortableContainer(({ items }) => {
    return (
      <div>
        {map(items, (slide, index) => {
          return (
            <SortableSlide
              key={`slide-${index}`}
              index={index}
              position={index}
              slide={slide}
            />
          );
        })}
      </div>
    );
  });

  const renderSlides = () => {
    if (reorderActive) {
      return (
        <SortableSlidesContainer
          items={slides}
          onSortEnd={onSortEnd}
          axis="xy"
          useDragHandle
        />
      );
    }

    return map(slides, (slide, index) => renderSlide(slide, index));
  };

  return (
    <AvForm
      onValidSubmit={onSubmit}
      onInvalidSubmit={() => onInvalidTacticSubmit(dispatch)}
    >
      <TacticExpandCollapseAll />

      <CampaignTacticSettings
        formData={formData}
        onChange={onChange}
        includeIsiField={false}
        includeCMECreditField={true}
      />

      <CMECenterFeedcard formData={formData} onChange={onChange} />

      <CMEFeedcard formData={formData} onChange={onChange} />

      {renderSlides()}

      <div className="add-slide" onClick={addSlide}>
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
        ADD SECTION
      </div>

      {size(slides) > 1 && (
        <div className="d-flex justify-content-end">
          <Button color="link" onClick={toggleReorder}>
            {reorderActive
              ? "Disable Slide Reordering"
              : "Enable Slide Reordering"}
          </Button>
        </div>
      )}

      <CampaignFormButtons saving={saving} caseData={caseData} />
      <div ref={slidesBottom} />
    </AvForm>
  );
};

CampaignClinicalMomentCMEForm.propTypes = {
  activeSection: PropTypes.object,
  caseData: PropTypes.object,
};

const CampaignSlideContainer = (props) => {
  const { formData, setFormData, slideIndex, slideTypes, reorderActive } =
    props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);
  const [removeSlideModal, setRemoveSlideModal] = useState(false);
  const toggleRemoveSlideModal = () => setRemoveSlideModal(!removeSlideModal);
  const prefix = `slides[${slideIndex}]`;
  const slideType = get(formData, `${prefix}.section`);

  const onChange = (event) => {
    const { name, value } = event.target;
    setFormData(clone(set(formData, name, value)));
  };

  const removeSlide = () => {
    const slides = getFieldValue(formData, "slides");
    pullAt(slides, [slideIndex]);
    setFormData(clone(formData));
  };

  const renderSlideTypeData = () => {
    switch (slideType) {
      case "FRONT_MATTER":
        return (
          <FrontMatterSection
            formData={formData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case "PRE_TEST":
        return (
          <QuestionsSection
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
            correctAnswer={false}
          />
        );
      case "POST_TEST":
        return (
          <QuestionsSection
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
            includeConclusion={true}
          />
        );
      case "SURVEY":
        return (
          <QuestionsSection
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
            correctAnswer={false}
            allowFreeForm={true}
          />
        );
      case "ACTIVITY":
        return (
          <ActivitySection
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
          />
        );
      case "CERTIFICATES":
        return (
          <CertificatesSection
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
          />
        );
      default:
        return null;
    }
  };

  const renderSlideName = () => {
    if (isEmpty(slideType)) {
      return `Section`;
    }

    return `Section: ${CLINICAL_MOMENTS_CME_SLIDE_TYPES[slideType]}`;
  };

  const DragHandle = SortableHandle(() => (
    <span className="drag-handle mr-2 text-secondary cursor-grab">
      <FontAwesomeIcon icon={faArrowsAlt} />
    </span>
  ));

  return (
    <div className="bg-white p-3 mt-4">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">
          {reorderActive && <DragHandle />}
          {renderSlideName()}
        </div>
        <RowToggler
          toggle={toggleCollapse}
          collapse={collapse}
          large={true}
          disabled={reorderActive}
        />
      </div>
      {!reorderActive && (
        <Collapse isOpen={collapse} className="pt-2 slide-body">
          <AvField
            type="select"
            name={getFieldName("section", prefix)}
            labelClass="text-11 text-uppercase font-weight-normal"
            label="section type"
            value={slideType || ""}
            onChange={onChange}
          >
            <option value="">Select...</option>
            {map(slideTypes, (v, key) => {
              return (
                <option key={`slide-type-${key}`} value={key}>
                  {v}
                </option>
              );
            })}
          </AvField>

          {renderSlideTypeData()}

          <div className="d-flex justify-content-start pt-3">
            <Button size="md" color="red" onClick={toggleRemoveSlideModal}>
              Delete Section
            </Button>
          </div>
        </Collapse>
      )}

      <ConfirmModal
        toggle={toggleRemoveSlideModal}
        isOpen={removeSlideModal}
        message="Are you sure you want to delete section?"
        onConfirm={removeSlide}
      />
    </div>
  );
};

CampaignSlideContainer.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  slideIndex: PropTypes.number,
  slideTypes: PropTypes.object,
  reorderActive: PropTypes.bool,
};

const CMECenterFeedcard = (props) => {
  const { formData, onChange } = props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);

  return (
    <div className="bg-white p-3 mt-3">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">CME Center Card</div>
        <RowToggler toggle={toggleCollapse} collapse={collapse} large={true} />
      </div>
      <Collapse isOpen={collapse} className="pt-2 slide-body">
        <AvField
          name={getFieldName(getFieldName("cme_center_card.title"))}
          value={getFieldValue(formData, "cme_center_card.title")}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "cme_center_card.title"),
            39
          )}
          label="activity title"
          placeholder="Enter title here..."
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          onChange={onChange}
          required
        />
        <AvField
          name={getFieldName(getFieldName("cme_center_card.heading"))}
          value={getFieldValue(formData, "cme_center_card.heading")}
          helpMessage={getFieldValueCharacters(
            getFieldValue(formData, "cme_center_card.heading"),
            15
          )}
          label="earnable credits text (markdown supported)"
          placeholder="Enter credit text here..."
          type="text"
          labelClass="text-11 text-uppercase font-weight-normal"
          onChange={onChange}
          required
        />
        <CampaignCaseSingleImageSection
          formData={formData}
          onChange={onChange}
          fieldName={getFieldName("cme_center_card.media")}
        />
      </Collapse>
    </div>
  );
};

const CMEFeedcard = (props) => {
  const { formData, onChange } = props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);
  const feedcardActivityType = get(
    formData,
    "cme_feed_card.feed_activity_type"
  );
  const displayFields = isEqual(feedcardActivityType, "show");

  return (
    <div className="bg-white p-3 mt-3">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">CME Feedcard</div>
        <RowToggler toggle={toggleCollapse} collapse={collapse} large={true} />
      </div>
      <Collapse isOpen={collapse} className="pt-2 slide-body">
        <FormGroup>
          <div className="d-flex justify-content-between">
            <Label className="text-11 text-uppercase font-weight-normal">
              Feed Activity
            </Label>
            <span className="text-11 text-secondary"></span>
          </div>
          <div>
            <CustomInput
              id={getFieldName("feed_activity_type_show")}
              type="radio"
              label="Show feedcard in feed"
              name={getFieldName("cme_feed_card.feed_activity_type")}
              value={"show"}
              checked={isEqual(
                toLower(
                  getFieldValue(formData, "cme_feed_card.feed_activity_type")
                ),
                "show"
              )}
              onChange={onChange}
              inline
            />
            <CustomInput
              id={getFieldName("feed_activity_type_hidden")}
              type="radio"
              label="Don't show feedcard in feed"
              name={getFieldName("cme_feed_card.feed_activity_type")}
              value={"hidden"}
              checked={isEqual(
                toLower(
                  getFieldValue(formData, "cme_feed_card.feed_activity_type")
                ),
                "hidden"
              )}
              onChange={onChange}
              inline
            />
          </div>
        </FormGroup>
        {displayFields && (
          <>
            <AvField
              name={getFieldName("cme_feed_card.feed_card_label")}
              value={getFieldValue(formData, "cme_feed_card.feed_card_label")}
              helpMessage={getFieldValueCharacters(
                getFieldValue(formData, "cme_feed_card.feed_card_label"),
                23
              )}
              label="Content label"
              placeholder="Enter label here..."
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
            <AvField
              name={getFieldName("cme_feed_card.feed_card_title")}
              value={getFieldValue(formData, "cme_feed_card.feed_card_title")}
              helpMessage={getFieldValueCharacters(
                getFieldValue(formData, "cme_feed_card.feed_card_title"),
                84
              )}
              label="banner title"
              placeholder="Enter banner here..."
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
            <AvField
              name={getFieldName("cme_feed_card.heading")}
              value={getFieldValue(formData, "cme_feed_card.heading")}
              helpMessage={getFieldValueCharacters(
                getFieldValue(formData, "cme_feed_card.heading"),
                60
              )}
              label="earnable credits text (markdown supported)"
              placeholder="Enter credit text here..."
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
            <CampaignCaseSingleImageSection
              formData={formData}
              onChange={onChange}
              fieldName={getFieldName("cme_feed_card.feed_card_media")}
            />
            <AvField
              name={getFieldName("cme_feed_card.colour")}
              value={getFieldValue(formData, "cme_feed_card.colour")}
              label="banner colour code"
              placeholder="Hex value here…"
              type="text"
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
          </>
        )}
      </Collapse>
    </div>
  );
};

const FrontMatterSection = (props) => {
  const { formData, onChange, prefix } = props;

  const handleChange = (value) => {
    onChange({ target: { name: getFieldName("caption", prefix), value } });
  };

  return (
    <>
      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          caption
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "caption", prefix)}
          options={{
            hideIcons: ["image"],
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("caption", prefix)}
          value={getFieldValue(formData, "caption", prefix)}
          required
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>
      <AvField
        name={getFieldName("references", prefix)}
        value={getFieldValue(formData, "references", prefix)}
        label="references (markdown supported)"
        placeholder="References here..."
        type="textarea"
        rows="4"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
      />
      <AvField
        name={getFieldName("button_text", prefix)}
        value={getFieldValue(formData, "button_text", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "button_text", prefix),
          24
        )}
        label="cta button text"
        placeholder="Button text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
    </>
  );
};

const QuestionsSection = (props) => {
  const {
    formData,
    setFormData,
    onChange,
    prefix,
    correctAnswer = true,
    includeConclusion = false,
    allowFreeForm = false,
  } = props;

  const questionsFieldName = getFieldName("questions", prefix);
  const questions = getFieldValue(formData, "questions", prefix, []);

  const addQuestion = () => {
    const name = `${questionsFieldName}[${size(questions)}]`;
    const newQuestion = {
      caption: "",
      question_options: [clone(DEFAULT_QUIZ_CME_ANSWER_SHAPE)],
    };
    setFormData(clone(set(formData, name, newQuestion)));
  };

  const removeQuestion = (index) => {
    pullAt(questions, [index]);
    setFormData(clone(formData));
  };

  return (
    <>
      {map(questions, (q, index) => {
        const questionPrefix = `${questionsFieldName}[${index}]`;
        const questionOptions = getFieldValue(
          formData,
          "question_options",
          questionPrefix
        );
        const isFreeForm = getFieldValue(
          formData,
          `question_options[0].is_free_form`,
          questionPrefix,
          false
        );

        const onChangeCheckbox = (event, name) => {
          const { checked } = event.target;
          onChange({ target: { name, value: checked } });
        };

        const onChangeFreeForm = (event) => {
          const { checked } = event.target;

          const name = getFieldName("question_options", questionPrefix);
          setFormData(
            clone(
              set(formData, name, [
                { ...DEFAULT_QUIZ_CME_ANSWER_SHAPE, is_free_form: checked },
              ])
            )
          );
        };

        const onAddQuestionOption = () => {
          questionOptions.push(clone(DEFAULT_QUIZ_ANSWER_SHAPE));
          setFormData(clone(formData));
        };

        const onRemoveQuestionOption = (index) => {
          pullAt(questionOptions, [index]);
          setFormData(clone(formData));
        };

        const validate = (value, ctx) => {
          if (isEmpty(questionOptions)) {
            return "You need to provide at least 1 question";
          }

          const selectedAnswer = find(questionOptions, (q) => q.is_answer);
          if (!selectedAnswer) {
            return "You must select at least 1 correct answer";
          }

          return true;
        };

        return (
          <React.Fragment key={`${prefix}-${index}`}>
            <div className="mb-2 pb-1 pt-2 border-bottom">
              Question {index + 1}
            </div>
            <AvField
              name={getFieldName("caption", questionPrefix)}
              value={getFieldValue(formData, "caption", questionPrefix)}
              helpMessage={getFieldValueCharacters(
                getFieldValue(formData, "caption", questionPrefix),
                112
              )}
              label={"Title / Question"}
              placeholder="Enter question here..."
              type="textarea"
              labelClass="text-11 text-uppercase font-weight-normal"
              onChange={onChange}
              required
            />
            <div>
              {correctAnswer && (
                <AvField
                  name={getFieldName("quiz-question", questionPrefix)}
                  value="empty"
                  groupAttrs={{ className: "hidden-validation" }}
                  type="hidden"
                  validate={{ myValidation: validate }}
                />
              )}

              <div className="d-flex justify-content-between">
                <Label className="text-11 text-uppercase font-weight-normal">
                  answer options
                </Label>
                {correctAnswer && (
                  <Label className="text-11 text-uppercase font-weight-normal">
                    select correct answer(s)
                  </Label>
                )}
                {allowFreeForm && (
                  <CustomInput
                    id={getFieldName(
                      `question_options[${index}].is_free_form`,
                      questionPrefix
                    )}
                    type="checkbox"
                    checked={isFreeForm}
                    className="text-11 text-uppercase font-weight-normal mb-1"
                    label={"Allow free-form answer field"}
                    onChange={(e) => onChangeFreeForm(e)}
                  />
                )}
              </div>
              {!isFreeForm &&
                map(questionOptions, (o, index) => {
                  const disableRemove = size(questionOptions) <= 1;

                  return (
                    <div
                      key={`option-value-${index}`}
                      className="d-flex justify-content-between align-middle "
                    >
                      <AvField
                        name={getFieldName(
                          `question_options[${index}].text`,
                          questionPrefix
                        )}
                        helpMessage={getFieldValueCharacters(o.text || "", 100)}
                        value={o.text || ""}
                        groupAttrs={{ className: "add-remove-icons flex-fill" }}
                        placeholder={`Option ${index + 1}`}
                        type="text"
                        onChange={onChange}
                        errorMessage="Enter a valid answer option"
                        required
                      />

                      <div>
                        <Button
                          onClick={() => onRemoveQuestionOption(index)}
                          disabled={disableRemove}
                          color="link"
                          className="border-right border-top border-bottom rounded-0"
                        >
                          <FontAwesomeIcon icon={faMinus} size={"sm"} />
                        </Button>
                        <Button
                          onClick={onAddQuestionOption}
                          color="link"
                          className="border-right border-top border-bottom rounded-0"
                        >
                          <FontAwesomeIcon icon={faPlus} size={"sm"} />
                        </Button>
                      </div>

                      {correctAnswer && (
                        <CustomInput
                          id={getFieldName(
                            `question_options[${index}].is_answer`,
                            questionPrefix
                          )}
                          type="checkbox"
                          checked={o.is_answer || false}
                          className="ml-4 pt-1"
                          onChange={(e) =>
                            onChangeCheckbox(
                              e,
                              getFieldName(
                                `question_options[${index}].is_answer`,
                                questionPrefix
                              )
                            )
                          }
                        />
                      )}
                    </div>
                  );
                })}
            </div>

            <div className="d-flex justify-content-start pb-3">
              <Button
                size="md"
                color="primary-dark"
                onClick={() => removeQuestion(index)}
              >
                Delete Question
              </Button>
            </div>
          </React.Fragment>
        );
      })}

      <div className="mt-3 add-slide" onClick={addQuestion}>
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
        ADD QUESTION
      </div>

      {includeConclusion && (
        <div className="mt-4">
          <div className="pb-2 d-flex justify-content-between">
            <div className="font-weight-500">Conclusion</div>
          </div>

          <AvField
            name={getFieldName("passing_score")}
            value={getFieldValue(formData, "passing_score")}
            label={"Number of answers required to pass post-test"}
            placeholder="Enter number here..."
            labelClass="text-11 text-uppercase font-weight-normal"
            type="number"
            onChange={onChange}
            required
          />
        </div>
      )}
    </>
  );
};

const ActivitySection = (props) => {
  const { formData, onChange, setFormData, prefix } = props;

  const activitySlidesFieldName = getFieldName("slides", prefix);
  const activitySlides = getFieldValue(formData, "slides", prefix, []);

  const addActivitySlide = () => {
    const name = `${activitySlidesFieldName}[${size(activitySlides)}]`;
    const newSlide = {
      slideType: "",
      mediaType: STATIC_MEDIA_TYPE.NO_MEDIA,
    };
    setFormData(clone(set(formData, name, newSlide)));
  };

  const removeActivitySlide = (index) => {
    pullAt(activitySlides, [index]);
    setFormData(clone(formData));
  };

  return (
    <>
      <ActivitySlideContainer
        formData={formData}
        onChange={onChange}
        setFormData={setFormData}
        prefix={`${prefix}.titleSlide`}
        slideName="Title"
        slideType={CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE}
        showSlideType={false}
      />

      {map(activitySlides, (q, index) => {
        const slidePrefix = `${activitySlidesFieldName}[${index}]`;

        return (
          <ActivitySlideContainer
            key={`activity-slide-${index}`}
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={slidePrefix}
            slideName={q.slideType}
            slideType={q.slideType}
            removeSlide={() => removeActivitySlide(index)}
          />
        );
      })}

      <div className="add-slide" onClick={addActivitySlide}>
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
        ADD SLIDE
      </div>
    </>
  );
};

const ActivitySlideContainer = (props) => {
  const {
    slideName,
    removeSlide,
    showSlideType = true,
    prefix,
    onChange,
    setFormData,
    formData,
    slideType,
  } = props;

  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => setCollapse(!collapse);
  const [removeSlideModal, setRemoveSlideModal] = useState(false);
  const toggleRemoveSlideModal = () => setRemoveSlideModal(!removeSlideModal);

  const renderSlideTypeData = () => {
    switch (slideType) {
      case CLINICAL_MOMENTS_SLIDE_TYPES.COVER_SLIDE:
        return (
          <SlideCover
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.BASIC:
        return (
          <SlideBasic
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
            includeIsiField={false}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.QUIZ:
        return (
          <SlideQuiz
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.POLL:
        return (
          <SlidePoll
            formData={formData}
            setFormData={setFormData}
            onChange={onChange}
            prefix={prefix}
          />
        );
      case CLINICAL_MOMENTS_SLIDE_TYPES.CONCLUSION:
        return (
          <SlideConclusion
            formData={formData}
            onChange={onChange}
            setFormData={setFormData}
            prefix={prefix}
            includeIsiField={false}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-3 border-bottom border-top">
      <div className="d-flex justify-content-between" onClick={toggleCollapse}>
        <div className="font-weight-500">{`Slide: ${slideName}`}</div>
        <RowToggler toggle={toggleCollapse} collapse={collapse} large={true} />
      </div>
      <Collapse isOpen={collapse} className="pt-2">
        {showSlideType && (
          <AvField
            type="select"
            name={getFieldName("slideType", prefix)}
            labelClass="text-11 text-uppercase font-weight-normal"
            label="section type"
            value={slideType || ""}
            onChange={onChange}
          >
            <option value="">Select...</option>
            {map(ACTIVITY_SLIDE_TYPES, (v, key) => {
              return (
                <option key={`activity-slide-type-${key}`} value={v}>
                  {v}
                </option>
              );
            })}
          </AvField>
        )}

        {renderSlideTypeData()}

        {removeSlide && (
          <div className="d-flex justify-content-start pt-3">
            <Button size="md" color="red" onClick={toggleRemoveSlideModal}>
              Delete Slide
            </Button>
          </div>
        )}
      </Collapse>

      <ConfirmModal
        toggle={toggleRemoveSlideModal}
        isOpen={removeSlideModal}
        message="Are you sure you want to delete slide?"
        onConfirm={removeSlide}
      />
    </div>
  );
};

const SlideCover = (props) => {
  const { formData, onChange, prefix } = props;

  const handleChange = (value) => {
    onChange({
      target: { name: getFieldName("caption", prefix), value },
    });
  };

  const onImageChange = (e) => {
    // force the mediatype to be an image when the image is set _here_
    onChange({
      target: {
        name: getFieldName("mediaType", prefix),
        value: STATIC_MEDIA_TYPE.IMAGE,
      },
    });
    // pass along original image setting
    onChange(e);
  };

  return (
    <>
      <AvField
        name={getFieldName("feed_card_label", prefix)}
        value={getFieldValue(formData, "feed_card_label", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "feed_card_label", prefix),
          20
        )}
        label="Content label"
        placeholder="Enter label here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("title", prefix)}
        value={getFieldValue(formData, "title", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "title", prefix),
          93
        )}
        label="Intro slide card title"
        placeholder="Enter slide card title here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("heading", prefix)}
        value={getFieldValue(formData, "heading", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "heading", prefix),
          52
        )}
        label="earnable credits text (markdown supported)"
        placeholder="Enter credit text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <AvField
        name={getFieldName("button_text", prefix)}
        value={getFieldValue(formData, "button_text", prefix)}
        helpMessage={getFieldValueCharacters(
          getFieldValue(formData, "button_text", prefix),
          17
        )}
        label="button text"
        placeholder="Enter button text here..."
        type="text"
        labelClass="text-11 text-uppercase font-weight-normal"
        onChange={onChange}
        required
      />
      <CampaignCaseSingleImageSection
        formData={formData}
        onChange={onImageChange}
        fieldName={getFieldName("media[0]", prefix)}
      />

      <AvGroup>
        <Label className="text-11 text-uppercase font-weight-normal">
          caption (markdown supported)
        </Label>
        <SimpleMDEEditor
          onChange={handleChange}
          value={getFieldValue(formData, "caption", prefix)}
          options={{
            hideIcons: ["image"],
          }}
        />
        <FormText>1400 max characters</FormText>
        <AvInput
          type="hidden"
          name={getFieldName("caption", prefix)}
          value={getFieldValue(formData, "caption", prefix)}
        />
        <AvFeedback>This field is required</AvFeedback>
      </AvGroup>

      <CampaignFormCommonElements
        formData={formData}
        onChange={onChange}
        prefix={prefix}
        isiActive={false}
      />
    </>
  );
};

const SlideQuiz = (props) => {
  const { formData, setFormData, onChange, prefix } = props;

  return (
    <>
      <CampaignQuizOrPollInputs
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        isQuiz={true}
        prefix={prefix}
        isiActive={false}
        includeIsiToggle={false}
        singleImage={true}
      />
    </>
  );
};

const SlidePoll = (props) => {
  const { formData, setFormData, onChange, prefix } = props;

  return (
    <>
      <CampaignQuizOrPollInputs
        formData={formData}
        setFormData={setFormData}
        onChange={onChange}
        isQuiz={false}
        prefix={prefix}
        isiActive={false}
        includeIsiToggle={false}
        singleImage={true}
      />
    </>
  );
};

const CertificatesSection = (props) => {
  const { formData, setFormData } = props;

  const professions = useSelector((state) => state.reference.professions);
  const certificatesFieldName = getFieldName("certificates");
  const certificates = getFieldValue(formData, "certificates");
  const allProfessionUuids = compact(map(professions, (p) => p.typeUuid));

  const addCertificate = () => {
    const name = `${certificatesFieldName}[${size(certificates)}]`;
    setFormData(
      clone(
        set(formData, name, {
          profession_tree_uuids: [],
        })
      )
    );
  };

  const removeCertificate = (index) => {
    pullAt(certificates, [index]);
    setFormData(clone(formData));
  };

  return (
    <>
      {map(certificates, (certificate, index) => {
        const onChangeAudience = (event) => {
          const { name } = event.target;
          const uuids = certificate.profession_tree_uuids;
          certificate.profession_tree_uuids = compact(
            includes(uuids, name) ? without(uuids, name) : union(uuids, [name])
          );

          setFormData(clone(formData));
        };

        const uploadCertificate = (uploadedCert) => {
          certificate.filename = get(uploadedCert, "filename");
          certificate.path = get(uploadedCert, "path");

          setFormData(clone(formData));
        };

        const allSelected =
          size(professions) === size(certificate.profession_tree_uuids);
        const onSelectAll = () => {
          certificate.profession_tree_uuids = !allSelected
            ? allProfessionUuids
            : [];
          setFormData(clone(formData));
        };

        return (
          <div className="pb-3" key={`certificate-${index}`}>
            <div className="pb-3 font-weight-500">Certificate {index + 1}</div>
            <div className="pb">
              <div>
                <Label className="text-11 text-uppercase font-weight-normal">
                  select audience
                </Label>

                <FormGroup check>
                  <Label check className="font-weight-bold">
                    <Input
                      type="checkbox"
                      name="select-all-cert-prof"
                      checked={allSelected}
                      onChange={onSelectAll}
                    />{" "}
                    Select all
                  </Label>
                </FormGroup>

                {map(professions, (profession, professionIndex) => {
                  return (
                    <FormGroup key={`label-${index}-${professionIndex}`} check>
                      <Label check>
                        <Input
                          type="checkbox"
                          name={profession.typeUuid}
                          checked={includes(
                            certificate.profession_tree_uuids,
                            profession.typeUuid
                          )}
                          onChange={onChangeAudience}
                        />{" "}
                        {profession.name}
                      </Label>
                    </FormGroup>
                  );
                })}
              </div>
            </div>
            <CMECertificate
              certificate={certificate}
              setCertificate={uploadCertificate}
            />

            <div className="d-flex justify-content-start pb-3">
              <Button
                size="md"
                color="primary-dark"
                onClick={() => removeCertificate(index)}
              >
                Delete Certificate
              </Button>
            </div>
          </div>
        );
      })}

      <div className="add-slide" onClick={addCertificate}>
        <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
        ADD CERTIFICATE
      </div>
    </>
  );
};

const CMECertificate = (props) => {
  const { certificate, setCertificate } = props;
  const [uploading, setUploading] = useState(false);
  const certificateFilename = get(certificate, "filename");

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const file = head(acceptedFiles);
      if (!file) {
        return;
      }

      setUploading(true);
      const response = await campaignCertificateUploadCall(file);
      const uploadedCertificate = get(response, "certificate");
      if (uploadedCertificate) {
        setCertificate(uploadedCertificate);
      }
      setUploading(false);
    },
    [setCertificate]
  );

  const onRemove = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setCertificate(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  return (
    <div className="add-slide mb-3" {...getRootProps()}>
      <input {...getInputProps()} />

      {uploading && (
        <div className="centered-container">
          <Spinner color="primary" />
        </div>
      )}

      {!uploading && !certificateFilename && (
        <>
          <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
          UPLOAD CERTIFICATE FILE HERE (MUST BE .docx)
        </>
      )}

      {!uploading && certificateFilename && (
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-18 font-weight-500">{certificateFilename}</div>
          <div className="d-inline-flex justify-content-end ml-3">
            <Button className="btn-round" color="danger" onClick={onRemove}>
              <FontAwesomeIcon icon={faTrash} color="white" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

CMECertificate.propTypes = {
  certificate: PropTypes.object,
  setCertificate: PropTypes.func,
};
