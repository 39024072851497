import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

const resolveConfiguration = () => {
  return config;
};

const defaultApp = app.initializeApp(resolveConfiguration());
const db = defaultApp && defaultApp.database ? defaultApp.database() : {};
const firestore =
  defaultApp && defaultApp.firestore ? defaultApp.firestore() : {};
const auth = app && app.auth ? app.auth() : {};
const storage = app && app.storage ? app.storage() : {};
const functions = app && app.functions ? app.functions() : {};
const provider = new app.auth.GoogleAuthProvider();

const Firebase = {
  db,
  firestore,
  auth,
  storage,
  functions,
  defaultApp,
  app,
  provider
};
export default Firebase;
